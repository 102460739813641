$small-desktop-width: 1200px;
$phone-width: 550px;
$tablet-width: 1100px;



@mixin desktopOnly {
  @media (min-width: #{$small-desktop-width}) {
    @content;
  }
}
@mixin smallDesktop {
  @media (max-width: #{$small-desktop-width}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}
@mixin phone {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}



*{
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

h1{
  margin-top: 10em;
  font-size: 150%;
  text-transform: uppercase;
  font-family:"Arial Black";
  position: relative;
}

h2{
  font-size: 38px;
  text-transform: uppercase;
}

main{
  min-height: 200vh;
}

.container{
  max-width: 1200px;
  width: 1200px;
  margin: 0 auto;
  @include tablet{
    max-width: 90%;
    width: 90%;
  }
}
section{
  min-height: 100vh;
  overflow: hidden;
  h2{
    margin-bottom: 35px;
  }
}

section:not(.hero_section){
  padding-block: 45px;
  display:flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  &:nth-child(odd){
    background-color: #474347;
    color: white;
  }
}

#services{
  .container{
    .services_container{
      display:flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      .service_card{
        width: 33%;
        padding-right: 15px;
        box-sizing: border-box;
        margin-bottom: 15px;
        @include tablet(){
          width: 50%;
        }
        @include phone(){
          width: 100%;
        }
        h3{
          margin-bottom: 10px;
        }
        li{
          margin-bottom: 5px;
        }
        ul{
          list-style: inside;
        }
      }
    }
  }
}

#valeurs{
  .container{
    ul{
      display: flex;
      justify-content: space-between;
      padding-left: 20px;

      li{
        width: 30%;
      }
    }
    .valeurs_container{
      .valeur_card{

        h3{
          margin-bottom: 10px;
        }
      }
    }
    .container{
      p {
        @include phone(){
          font-size: x-large;
          padding-left: 0;
        }

        @include tablet(){
          font-size: x-large;
          padding-left: 0;
        }

        font-size: xx-large;
        padding-left: 5em;
      }

    }
  }
}
.hero_section{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container{
    position: relative;
    z-index: 2;
    h1{
      color: #005D7E;
      width: 60%;
      @include tablet(){
        width: 100%;
      }
    }
  }
}

.filter_black{
  position: relative;
  &:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    //Le after c'est juste un calque qui modifie la couleur de la photo dans le background, faut pas mettre d'image dedans
    //background-image: url("../public/img/Equipe.jpg");
    overflow: hidden;
    background-size: cover;
    background-position: center;
    margin: 180px 0 0 0;
    opacity: .5;
    z-index: 1;
  }
}
#mission{
  h2{
    margin-top: 1em;
    color: white;
  }
  p{
    margin-top: 4em;
    color: white;
    font-size: 1.7em;
  }
  background-image: url("../public/img/photoBackgroundEngagement.jpg");
  background-position: center;
  background-size: cover;
}
#equipe{
  .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h2{ width: 100%; }
    .equipe_card{
      width: 30%;
      box-sizing: border-box;
      border: 2px solid #005D7E;
      margin-bottom: 45px;
      @include phone(){
        width: 100%;
      }
      .img_container{
        width: 100%;
        border-bottom: 2px solid #005D7E;
        img{
          width: 100%;
          max-height: 320px;
          height: auto;
          object-fit: cover;
          object-position: 0% 20%;
        }
      }
      .equipe_card_content{
        padding: 30px;
        h3{
          margin-bottom: 10px;
          font-size: 1.15em;
        }
        h4{
          margin-bottom: 10px;
        }
        ul{list-style: none}
        ul li{margin-bottom: 5px;}
      }
    }
  }
}